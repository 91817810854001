import { configureStore } from '@reduxjs/toolkit'
import sidebarValue from './slices/sidebarValue'
import tabValue from './slices/tabValue'

export const store = configureStore({
    reducer: {
        sidebarValue: sidebarValue,
        tabValue
    },
})

export default store