import React, { useEffect, useRef, useState } from 'react'
import exp from "../assets/images/exp.png"
// import Button from '../components/franchise/commonButton/Button';

function TokenExpiredModal() {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const showHandler = () => {
            setShowModal(true);
        };
        document.addEventListener("showTokenExpiredModal", showHandler);
        return () => {
            document.removeEventListener("showTokenExpiredModal", showHandler);
        };
    }, []);

    const handleLogout = () => {
        localStorage.clear()
        window.location.reload()
    };

    return (
        <div style={{ display: showModal ? "flex" : "none", width: "100%", justifyContent: "center" }}
            className='flex flex-col justify-center items-center w-full h-full z-[9999] fixed
             top-0 right-0 bg-[rgba(0,0,0,0.21)]'>
            <div className='text-center bg-lightGrey sm:w-[480px] w-[95%] sm:px-14 px-2 border border-white py-8 rounded-lg'>
                <div className='bg-darkGray w-full  text-white flex justify-center flex-col items-center mb-4
                  text-center gap-4'>
                    <img className='w-24' src={exp} alt='' />
                    <p className={`text-xl sm:text-lg  font-semibold uppercase text-center text-primaryBg  px-6 sm:px-0`}>
                        Your Session has expired, kindly login again.</p>
                </div>
                <div className='px-28'>
                    <button
                        onClick={handleLogout}
                        className='bg-primary font-bold  text-white shadow-[0_3px_10px_rgb(0,0,0,0.2)] px-12 py-2 cursor-pointer rounded-lg'
                    >Login</button>
                </div>
            </div>
        </div>
    );
}


export default TokenExpiredModal;