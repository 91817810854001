import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    filter: "Today's",
    filterOvertime: "This Month",
}

export const tabValue = createSlice({
    name: 'tabValue',
    initialState,
    reducers: {
        setFilter: (state, action) => {
            state.filter = action.payload
        },
        setFilterOvertime: (state, action) => {
            state.filterOvertime = action.payload
        },
    },
})

export const { setFilter, setFilterOvertime } = tabValue.actions

export default tabValue.reducer