import MyRoutes from "./route/MyRoutes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TokenExpiredModal from "./utils/TokenExpiredModal";


function App() {
  return (
    <>
      <MyRoutes />
      <ToastContainer closeOnClick={true} />
      <TokenExpiredModal/>
    </>
  );
}

export default App;
